<template>
  <el-dialog title="隐患登记" class=" avue-dialog " :before-close="hanleBeforClose" :visible.sync="modelShow" width="80%">
      <div class="formContentBox">
        <div class="formMain">
            <el-form ref="dataForm" :model="dataForm" label-width="150px" :rules="formType == 'view' ? {} :rules"
                     :disabled="formType == 'view'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="隐患编号" prop="hdCode">
                    <el-input v-model="dataForm.hdCode" disabled placeholder="隐患编号自动生成"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="隐患地点" prop="location">
                    <el-input v-model="dataForm.location" placeholder="请填写隐患地点" :title="dataForm.location">
                      <template slot="append">
                        <div class="el-icon-map-location" style="width: 54px;text-align: center"
                             @click="openMap()"></div>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="项目区域" prop="area">
                    <el-input v-model="dataForm.area" ref="area" @focus="handleChangeArea()" placeholder="请选择项目区域"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="隐患评估责任人" prop="assessmentUser">
                    <el-input v-model="dataForm.assessmentUserName" ref="assessmentUserName" disabled :title="dataForm.assessmentUserName" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="报告人" prop="reportUserName">
                    <el-input v-model="dataForm.reportUserName" @focus="handleUserModeOpen()"
                              placeholder="请选择报告人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告部门" prop="reportDeptName">
                    <el-input v-model="dataForm.reportDeptName" @focus="handleDeptModeOpen()" :title="dataForm.reportDeptName"
                              placeholder="请选择报告部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告时间" prop="reportDate">
                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate"
                                    placeholder="请选择报告时间"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="隐患描述" prop="hdDesc">
                    <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit maxlength="225"
                              v-model="dataForm.hdDesc"
                              placeholder="请填写隐患描述"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="隐患照片" prop="treeData">
                    <el-upload
                      v-if="formType != 'view'"
                      accept=".jpg,.mp4,.png"
                      style="margin-bottom: 12px;display: flex; align-items: center;"
                      class="upload-demo"
                      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                      :show-file-list="false"
                      :before-upload="checkFileType"
                      :on-success="handleAvatarSuccess"
                      ref="uploadRef"
                      :headers="headers"
                    >
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-upload"
                      >点击上传
                      </el-button>
                    </el-upload>
                    <div class="content">
                      <div
                        v-for="(item, index) in treeData"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                      >
                        <img :src="item.thumbnailLink" alt=""/>
                        <i
                          v-if="formType !== 'view'"
                          class="el-icon-circle-close delete-icon"
                          @click.stop="handleRemove(item, index)"
                        ></i>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </div>
      </div>
    <el-dialog append-to-body title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData"></UserDetpDialog>
    </el-dialog>
    <el-dialog append-to-body title="部门选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData1"></DeptDialog>
    </el-dialog>
    <el-dialog title="选择位置" append-to-body :visible.sync="mapBox" top="7vh">
      <div style="width: 100%">
        <GaodeMap v-if="mapBox" ref="GaodeMap" @selectAddress="selectAddress" @getLocation="getLocation"></GaodeMap>
      </div>
      <span slot="footer">
        <el-button size="small" @click="celMap">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <div class="avue-dialog__footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button @click="saveData()" type="primary">确 定</el-button>
    </div>
    <el-dialog title="项目区域选择" :visible.sync="areaDialog" width="80%" append-to-body>
      <prjArea ref="prjArea" @prjAreaCallback="prjAreaCallback" dialogType />
    </el-dialog>
  </el-dialog>
  
</template>
<script>
import website from "@/config/website";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import {getToken} from "@/util/auth";
import * as API from "@/api/check/checkTasks";
import GaodeMap from "@/views/system/attendance/GaodeMap.vue";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import prjArea from "@/views/business/safe/itemList/projectArea/index.vue";
import { roleIds, getUsersByRoleIdAndDeptId } from "@/api/system/client";
import { mapGetters } from "vuex";
import { dateFormat } from "@/util/date";
export default {
  name: "model",
  components: {DeptDialog, GaodeMap, UserDetpDialog, prjArea},
  props:{
    callback:Function
  },
  data(){
    return {
      modelShow:false,
      deptShow:false,
      deptShow1:false,
      mapBox:false,
      treeData:[],
      dataForm:{
        reportUserName:'',
        hdDesc:'',
        reportDate:'',
        treeData:[],
      },
      formType:'view',
      rules: {
        treeData: [{
          required: false,
          message: '请上传隐患照片',
          trigger: 'blur'
        }],
        hdDesc: [{
          required: true,
          message: '隐患描述不能为空',
          trigger: 'blur'
        }],
        reportDate: [{
          required: true,
          message: '报告时间不能为空',
          trigger: 'change'
        }],
        reportUserName: [{
          required: true,
          message: '报告人不能为空',
          trigger: 'change'
        }],
        area: [{required: true, message: '项目区域不能为空', trigger: 'change' }],
        assessmentUser: [{required: true, message: '隐患评估责任人不能为空', trigger: 'blur' }],
      },
      areaDialog: false,
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
  },
  methods:{
    initData(row={},formType = 'edit'){
      this.formType=formType
      this.treeData = [];
      this.modelShow=true;
      this.dataForm=row;
      this.dataForm.reportDeptId = this.userInfo.deptId
      this.dataForm.reportDeptName = this.userInfo.deptName
      this.dataForm.reportDate = dateFormat(new Date())
      this.getAssessmentUser()
    },
    hanleBeforClose(done){
      this.$refs.dataForm.resetFields();
      this.modelShow = false;
      done()
    },
    handleCancel(){
      this.$refs.dataForm.resetFields()
      this.modelShow=false
    },
    saveData(){
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          if(!this.dataForm.taskId){
              this.$emit('getHidenList',this.dataForm)
              this.$loading().close();
              this.$refs.dataForm.resetFields()
              this.modelShow=false;
              return
          }
          API.hdchecktaskproSubmit({
            taskId:this.dataForm.taskId,
            hdHiddenDanger:{
              ...this.dataForm,
              taskId:'',
              hdStatus: 'unassess',
              hdUrl: JSON.stringify(this.treeData),
            },
          }).then(res=>{
            if(res.data.code == 200){
              this.callback && this.callback()
              this.$message.success(res.data.msg)
              this.$loading().close();
              this.$refs.dataForm.resetFields()
              this.modelShow=false;
            }else {
              this.$message.error(res.data.msg)
              this.$loading().close();
            }
          }).catch(err=>{
            this.$loading().close();
          })
        }
      })

    },
    selectData(row) {
      this.deptShow = false;
      this.$set(this.dataForm,'reportUser',row.id)
      this.$set(this.dataForm,'reportUserName',row.realName)
      // console.log("进行输出人员-------------------", row)
    },
    selectData1(row) {
      this.deptShow1 = false
      this.dataForm.reportDeptId = row.id
      this.dataForm.reportDeptName = row.deptName
      // console.log("进行输出人员-------------------", row)
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = (fileType === '.jpg' || fileType == '.png' || fileType == '.mp4');

      if (!isJPG) {
        this.$message.warning('上传图片只能是 .jpg  .png .mp4 格式!');
      }
      if (!isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
        originalName: file.response.data.name,
      });
      this.$set(this.dataForm, 'treeData', this.treeData)
      this.$refs.dataForm.validateField('treeData')
    },
    getNodeClick(node) {
      this.selectNode = node;
      this.showFile(node);
    },
    handleUserModeOpen() {
      this.deptShow = true
    },
    handleDeptModeOpen() {
      this.deptShow1 = true
    },

    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    celMap() {
      this.mapBox = false;
      this.$set(this.dataForm, 'location', '');
      this.$set(this.dataForm, 'latitude', '');
    },
    handleMapSave() {
      // 高德地图用
      this.mapBox = false;
    },
    // 高德地图用
    selectAddress(data) {
      this.$set(this.dataForm, 'location', data.address);
      this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
    },
    // 高德地图用
    getLocation(data) {
      this.$set(this.dataForm, 'location', data.address);
      this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
    },
    //获取经纬度
    openMap() {
      if (this.formType == 'view') return
      this.mapBox = true
    },

    //  选择项目区域
    handleChangeArea() {
      this.$refs.area.blur();
      this.areaDialog = true;
      this.$nextTick(() => {
        this.$refs.prjArea.init(this.dataForm.organizationId, "view");
      });
    },
    prjAreaCallback(data) {
      this.$set(this.dataForm, "areaId", data.id);
      this.$set(this.dataForm, "areaCode", data.areaCode);
      this.$set(this.dataForm, "area", data.areaName);
      this.areaDialog = false;
    },
    getAssessmentUser() {
      console.log(this.userInfo)
      roleIds({
          roleNames: "隐患接收人",
          tenantId: this.userInfo.tenant_id,
        }).then((res) => {
          if (res.data.code == 200) {
            let roleId = res.data.data;
            if (roleId && typeof roleId == "string") {
              getUsersByRoleIdAndDeptId({
                deptId: this.dataForm.organizationId,
                roleId,
                needChildren: "yes",
              }).then((result) => {
                let data = result.data.data;
                this.dataForm.assessmentDept = this.dataForm.organizationId;
                if (typeof data === "object" && Array.isArray(data)) {
                  let assessmentUser = data.map((val) => val.id).toString();
                  this.$set(this.dataForm, "assessmentUser", assessmentUser);
                  let assessmentUserName = data.map((val) => val.realName).toString();
                  this.$set(this.dataForm,"assessmentUserName",assessmentUserName);
                } else {
                  this.$message.warning("请设置该项目隐患接收人");
                }
              });
            }
          } else {
            this.$message.warning("系统未设置隐患接收人，请联系管理员");
          }
        });
    },
  },
}
</script>
<style scoped lang="scss">
.content {
  display: flex;
  align-items: center;

  .img-content {
    width: 188px;
    height: 100px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;

    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

}
</style>
